import React, { useContext, useState, useEffect } from "react";

import Paper from "@mui/material/Paper";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import FormControl from "@mui/material/FormControl";
import makeStyles from '@mui/styles/makeStyles';


import ChartComponent from "./Chart";
import SelectTags from "../../components/SelectTags";
import SelectUsers from "../../components/SelectUsers";
import SelectQueues from "../../components/SelectQueues";
import SelectContacts from "../../components/SelectContacts";

import DateRangePicker from "../../components/DateRangePicker";
import * as moment from 'moment';
import { AuthContext } from "../../context/Auth/AuthContext";
import { Can } from "../../components/Can";
import SelectStatus from "../../components/SelectStatus";
import ChartDailyTicketsComponent from "./ChartDailyTickets";
import ColorChartSelect from "../../helpers/colorChart";
import api from "../../services/api";
import MultipleChartComponent from "./MultipleChart";
import ChartResponseTimeComponent from "./ChartResponseTime";

const useStyles = makeStyles(theme => ({
    container: {
        width: '100%',
        padding: 10,
        overflow: 'hidden'
    },
    fixedHeightPaper: {
        //padding: theme.spacing(2),
        padding: 6,
        display: "flex",
        overflow: "auto",
        flexDirection: "column",
        height: 240,
        borderRadius: 0
    },
    highchartPaper: {
        height: 'auto',
        paddingLeft: '10px',
        marginBottom: 10,
        maxWidth: '100%',
        boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    blockListPaper: {
        height: 'auto',
        paddingLeft: '10px',
        paddingTop: '15px',
        paddingBottom: '15px',
        marginBottom: 10,
        maxWidth: '100%',
        rounded: 10,
        boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    filterContainer: {
        backgroundColor: '#FFF',
        marginBottom: 10
    },
    paper: {
        padding: theme.spacing(2),
        boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
    },
    wrapperSelect: {
        padding: 0
    },
    totalPaper: {
        fontSize: 20
    }
}));

const Report = () => {
    const classes = useStyles();
    const [period, setPeriod] = useState([moment().subtract(6, 'days').toDate(), moment().toDate()]);
    const [userIds, setUserIds] = useState([]);
    const [queueIds, setQueueIds] = useState([]);
    const [contactIds, setContactIds] = useState([]);
    const [tagIds, setTagIds] = useState([]);
    const [statuses, setStatuses] = useState([]);
    const [loadingTickets, setLoadingTickets] = useState(false);
    const [loadingResponseTime, setLoadingResponseTime] = useState(false);
    const [loadingTicketTime, setLoadingTicketTime] = useState(false);
    const [selectedTheme, setSelectedTheme] = useState('nivo');
    const { user, permissions } = useContext(AuthContext);



    const handleChangeUserIds = (values) => {
        setUserIds(values.map((item) => item.id));
    }

    const handleChangeQueueIds = (values) => {
        setQueueIds(values.map((item) => item.id));
    }
    const handleChangeContactIds = (values) => {
        setContactIds(values.map((item) => item.id));
    }
    const handleChangeTagIds = (values) => {
        setTagIds(values.map((item) => item.id));
    }

    const handleChangeStatuses = (values) => {
        setStatuses(values.map((item) => item.id));
    }


    const handleChangeSelectedTheme = (data) => {
        //console.log(data.target.value)
        setSelectedTheme(data.target.value)
        window.localStorage.setItem('selectedThemeChart', data.target.value);
    }


    useEffect(() => {

        const sendLog = async () => {
            await api.get('log/dashboard-resume')
        }

        async function getReportUser() {
            if ((user && user.roleId === 1) || permissions.some((permission) => permission.key === 'dashboard:show:reports:all')) {
                setUserIds([]);
            } else {
                if (user && user.queues && user.queues.length >= 1) {
                    //console.log(user.queues, 'find user.queues');
                    user.queues.forEach((values) => {
                        if (!queueIds.includes(values.id)) {
                            setQueueIds(prevQueueIds => [...prevQueueIds, values.id]);
                        }
                    });
                }
                else {
                    setUserIds([user.id]);
                }

            }
        }
        getReportUser();
        sendLog();
    }, [user]);

    return (
        <div>
            <Container maxWidth={false} className={classes.container}>
                <Grid container className={classes.filterContainer}>
                    <Grid item xs={12} md={12}>
                        <Paper className={classes.paper}>
                            <Grid container spacing={3}>

                                {(permissions.some((permission) => permission.key === 'dashboard:show:reports:all') | user.roleId === 1) ? (
                                    <>

                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <DateRangePicker
                                                    onChange={(p) => setPeriod(p)}
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectStatus outlined disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeStatuses} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectContacts disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeContactIds} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectUsers multiple={true} disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeUserIds} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectQueues multiple={true} disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeQueueIds} />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectTags outlined disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeTagIds} />
                                            </div>
                                        </Grid>

                                        <Grid item xs={12} md={12} style={{ padding: 0, margin: 0, marginTop: 10 }}>
                                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                                <FormControl size="small" variant="outlined" style={{ maxWidth: 330 }}>
                                                    <ColorChartSelect selectedTheme={selectedTheme} handleSelectChange={handleChangeSelectedTheme} />
                                                </FormControl>
                                            </div>
                                        </Grid>
                                    </>
                                ) : (
                                    <>
                                        <Grid item xs={12} md={4}>
                                            {user && user.queues && user.queues.length >= 1 ? (
                                                <div style={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: 10 }}>
                                                    <h5 style={{ marginBottom: 0 }}>Setores</h5>
                                                    <div style={{ display: 'flex', alignItems: 'center', gap: 5 }}>
                                                        {user.queues.map((values) => (
                                                            <h6 key={values.id} style={{ textAlign: 'center', display: 'flex', alignItems: 'center', gap: 4, textTransform: 'capitalize', marginBottom: 0 }}>
                                                                {/* <div style={{ width: 15, height: 15, background: values.color, borderRadius: 15 }}></div> */}
                                                                - {values.name}
                                                            </h6>
                                                        ))}
                                                    </div>
                                                </div>
                                            ) : (
                                                <>
                                                    <h5 style={{ marginBottom: 0 }}>Usuário</h5>
                                                    <h4 style={{ textTransform: 'capitalize' }}>{user.name}</h4>
                                                </>
                                            )}
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <DateRangePicker
                                                    onChange={(p) => setPeriod(p)}
                                                    defaultValue={period}
                                                    disabled={loadingTickets || loadingResponseTime || loadingTicketTime}
                                                />
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} md={4}>
                                            <div className={classes.wrapperSelect}>
                                                <SelectContacts disabled={loadingTickets || loadingResponseTime || loadingTicketTime} onChange={handleChangeContactIds} />
                                            </div>
                                        </Grid>
                                    </>
                                )}
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>


                <Can
                    role={user.roleId}
                    perform="dashboard:ticket-number"
                    yes={() => (
                        <MultipleChartComponent
                            contactIds={contactIds}
                            queueIds={queueIds}
                            tagIds={tagIds}
                            userIds={userIds}
                            statuses={statuses}
                            period={period}
                            endpoint="/report/tickets-reviews/"
                            title="Atendimentos Qualificados"
                            setLoading={setLoadingTickets}
                            yAxisTitle="Número de Atendimentos"
                            theme={selectedTheme}
                        />
                    )}
                    no={() => <></>}
                />



                <Grid item xs={12} md={12}>
                    <Can
                        role={user.roleId}
                        perform="dashboard:ticket-number"
                        yes={() => (
                            <Paper className={classes.highchartPaper}>
                                <ChartComponent
                                    contactIds={contactIds}
                                    queueIds={queueIds}
                                    tagIds={tagIds}
                                    userIds={userIds}
                                    statuses={statuses}
                                    period={period}
                                    endpoint="/report/ticket-number/"
                                    title="Atendimentos"
                                    setLoading={setLoadingTickets}
                                    yAxisTitle="Número de Atendimentos"
                                    theme={selectedTheme}
                                />
                            </Paper>
                        )}
                        no={() => <></>}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Can
                        role={user.roleId}
                        perform="dashboard:ticket-response-time"
                        yes={() => (
                            <Paper className={classes.highchartPaper}>
                                <ChartComponent
                                    contactIds={contactIds}
                                    queueIds={queueIds}
                                    tagIds={tagIds}
                                    userIds={userIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/ticket-response-time/"
                                    title="Tempo médio de Aceite do Aguardando"
                                    yAxisTitle="Tempo médio de Resposta Atendimentos em minutos"
                                    theme={selectedTheme}
                                    subtitle="minutos"
                                />
                            </Paper>)}
                        no={() => <></>}
                    />
                </Grid>


                <Grid item xs={12} md={12}>
                    <Can
                        role={user.roleId}
                        perform="dashboard:ticket-response-time"
                        yes={() => (
                            <Paper className={classes.highchartPaper}>
                                <ChartResponseTimeComponent
                                    contactIds={contactIds}
                                    queueIds={queueIds}
                                    tagIds={tagIds}
                                    userIds={userIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/message-response-time/"                                    
                                    title="Tempo Médio de Resposta das Mensagens"
                                    yAxisTitle="Tempo médio de Resposta Mensagens em minutos"
                                    theme={selectedTheme}
                                    subtitle="minutos"
                                />
                            </Paper>)}
                        no={() => <></>}
                    />
                </Grid>

                <Grid item xs={12} md={12}>
                    <Can
                        role={user.roleId}
                        perform="dashboard:ticket-number"
                        yes={() => (
                            <Paper className={classes.highchartPaper}>
                                <ChartDailyTicketsComponent
                                    contactIds={contactIds}
                                    queueIds={queueIds}
                                    tagIds={tagIds}
                                    userIds={userIds}
                                    statuses={statuses}
                                    period={period}
                                    setLoading={setLoadingTicketTime}
                                    endpoint="/report/daily-ticket-number/"
                                    title="Atendimentos por dia"
                                    yAxisTitle="Numero de atendimentos"
                                    theme={selectedTheme}
                                    subtitle="total"
                                />
                            </Paper>)}
                        no={() => <></>}
                    />
                </Grid>
            </Container >
        </div >
    );
};

export default Report;
