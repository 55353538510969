import React, { useState, useEffect } from "react";
import makeStyles from '@mui/styles/makeStyles';
import { useHistory } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";

import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import CircularProgress from "@mui/material/CircularProgress";
import { parseISO, format, isSameDay } from "date-fns";
import { i18n } from "../../translate/i18n";
import api from "../../services/api";
import ButtonWithSpinner from "../ButtonWithSpinner";
import toastError from "../../errors/toastError";
import UserStatusIcon from "../User/statusIcon";
import TicketInfo from "../TicketInfo";
import TicketTime from "../TicketTime";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { HiOutlineChatBubbleBottomCenterText } from 'react-icons/hi2';

const useStyles = makeStyles(theme => ({
    online: {
        background: 'green',
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        display: 'inline-block'
    },
    offline: {
        background: 'red',
        borderRadius: '50%',
        width: '10px',
        height: '10px',
        display: 'inline-block'
    }
}));

const SearchTicketModal = ({ modalOpen, onClose, ticket }) => {
    const classes = useStyles();
    const history = useHistory();
    const [options, setOptions] = useState([]);
    const [queues, setQueues] = useState([]);
    const [whatsapps, setWhatsapps] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadingWhatsapp, setLoadingWhatsapp] = useState(false);
    const [loadingQueue, setLoadingQueue] = useState(false);
    const [isSearched, setSearched] = useState(false);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [searchParam, setSearchParam] = useState("");
    const [searchParamWhatsapp, setSearchParamWhatsapp] = useState("");
    const [searchParamQueue, setSearchParamQueue] = useState("");
    const [selectedUser, setSelectedUser] = useState(null);
    const [selectedQueue, setSelectedQueue] = useState(null);
    const [selectedWhatsapp, setSelectedWhatsapp] = useState(null);
    const [results, setResults] = useState(null);
    const ticketid = ticket?.id;


    useEffect(() => {
        if (!modalOpen) {
            setLoading(false);
            return;
        }
        setLoading(true);
        const delayDebounceFn = setTimeout(() => {
            const fetchMessage = async () => {
                try {
                    const response = await api.get(`/tickets/${ticket.id}/search/${searchParam}`);
                    if (response.data) {
                        setResults(response.data.results.search)
                       console.log(response.data)
                    }

                    setLoading(false);
                } catch (err) {
                    setLoading(false);
                    toastError(err);
                }
            };
            if (searchParam.length >= 3) {
                fetchMessage();
                setLoadingSearch(false);
            }

            if (searchParam.length <= 2) {
                setResults(null);
                setLoading(false);
            }

            if (searchParam.length >= 1) {
                setSearched(true);
                setLoadingSearch(true);
            }
            if (searchParam.length < 1) {
            setLoadingSearch(false);
            setSearched(false)
            }
        }, 500);
        return () => clearTimeout(delayDebounceFn);
    }, [searchParam, modalOpen]);


    const handleClose = () => {
        onClose();
        setSearchParam("");
    };

    const handleSearch = (e) => {
        onClose();
        e.preventDefault();
    }

    const goToMessage = async (id) => {
        try {
            setLoading(true);

            history.push(`/tickets/${ticket.id}/${id}`);
            setLoading(false);
            onClose();
        } catch (err) {
            setLoading(false);
            toastError(err);
        }
    };



    return (
        <Dialog open={modalOpen} onClose={handleClose} maxWidth="lg" size={results ? 'lg' : 'md'} scroll="paper">
            <form onSubmit={handleSearch}>
                <DialogTitle id="form-dialog-title">
                    {i18n.t("searchTicketModal.title")}
                </DialogTitle>
                <DialogContent dividers>
                    <h5>{i18n.t("ticket")}</h5>
                    <TicketInfo
                        contact={ticket.contact}
                        ticket={ticket}
                    />

                    <TextField
                        label={i18n.t("searchTicketModal.fieldLabel")}
                        variant="outlined"
                        autoFocus
                        onChange={e => setSearchParam(e.target.value)}
                    />


                </DialogContent>

                <DialogContent style={{ maxHeight: 300, overflow: 'auto' }} dividers>
                    {isSearched && Array.isArray(results) ? (
                        <List>
                            {results.length >= 1 ? results.map((item, index) => (
                                <ListItem disablePadding key={index} onClick={() => goToMessage(item.id)}>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <HiOutlineChatBubbleBottomCenterText size={27} />
                                        </ListItemIcon>
                                        <ListItemText primary={item.body} style={{ maxWidth: '80%', paddingRight: 15 }} />
                                        <div style={{ display: 'flex', alignItems: 'center', gap: 5, justifyContent: 'end' }}>
                                            {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                                                <>{format(parseISO(ticket.updatedAt), "HH:mm")}</>
                                            ) : (
                                                <><TicketTime updatedAt={ticket.updatedAt} /></>
                                            )}
                                        </div>
                                    </ListItemButton>
                                </ListItem>
                            )) : (
                                <ListItem disablePadding>
                                    <ListItemButton>
                                        <ListItemIcon>
                                            <HiOutlineChatBubbleBottomCenterText size={27} />
                                        </ListItemIcon>
                                        <ListItemText primary={'Nada encontrado'} style={{ maxWidth: '80%', paddingRight: 15 }} />
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </List>
                    ) : isSearched ? (
                        <ListItem disablePadding>
                            <ListItemButton>
                                <ListItemIcon>
                                    <HiOutlineChatBubbleBottomCenterText size={27} />
                                </ListItemIcon>
                                <ListItemText primary={loadingSearch ? 'Digite pelo menos 3 caracteres' : 'Nada encontrado'} style={{ maxWidth: '80%', paddingRight: 15 }} />
                            </ListItemButton>
                        </ListItem>
                    ) : (<></>)}

                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleClose}
                        color="secondary"
                        disabled={loading}
                        variant="outlined"
                    >
                        {i18n.t("searchTicketModal.buttons.cancel")}
                    </Button>
                </DialogActions>
            </form>


        </Dialog>
    );
};

export default SearchTicketModal;
