import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import makeStyles from '@mui/styles/makeStyles';
import { BiMessageSquareX } from "react-icons/bi";
import { BsFillGridFill, BsFillGrid3X3GapFill } from "react-icons/bs";
import TicketsManager from "../../components/TicketsManager/";
import TicketsGridManager from "../../components/TicketsGridManager/";
import Ticket from "../../components/Ticket/";
import Title from "../../components/Title";
import { i18n } from "../../translate/i18n";
import Hidden from '@mui/material/Hidden';
import { BiHelpCircle } from "react-icons/bi";
import { useHistory } from "react-router-dom";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Can } from "../../components/Can";
import { AuthContext } from "../../context/Auth/AuthContext";
import ForbiddenPage from "../../components/ForbiddenPage";

const useStyles = makeStyles(theme => ({
	root: {
		background: theme.palette.fancyBackground,
		minHeight: '100%',
		padding: '0px 24px 24px 24px',
		[theme.breakpoints.down('md')]: {
			padding: '0px 5px 24px 5px',
		},
		'& h2': {
			color: theme.palette.dark.main
		}
	},
	title: {
		margin: '15px 0px',
		fontWeight: 700
	},
	chatContainer: {
		flex: 1,
		// backgroundColor: "#eee",
		//padding: theme.spacing(4),
		height: '100%',
		overflowY: "hidden",
		alignItems: 'center',
		display: 'flex',
		flexDirection: 'column',
		[theme.breakpoints.down('md')]: {
			flexDirection: 'initial'
		},
		gap: 4
	},

	chatPapper: {
		// backgroundColor: "red",
		display: "flex",
		height: "100%",
		width: "100%"
	},

	contactsWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
		transition: 'all ease 0.5s'
	},
	contactsWrapperSmall: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
		overflowY: "hidden",
		[theme.breakpoints.down('md')]: {
			display: "none"
		}
	},
	messagessWrapper: {
		display: "flex",
		height: "100%",
		flexDirection: "column",
	},
	welcomeMsg: {
		backgroundColor: theme.palette.background,
		display: "flex",
		flexDirection: 'column',
		width: '100%',
		justifyContent: "center",
		alignItems: "center",
		height: "100%",
		textAlign: "center",
		borderRadius: 20,
	},
	ticketsManager: {

	},
	ticketsManagerClosed: {
		[theme.breakpoints.down('md')]: {
			display: "none"
		}
	},
	flexButtons: {
		display: 'flex',
		zIndex: 9,
		alignItems: 'center',
		position: 'absolute',
		bottom: '10%',
		left: '-5px',
		borderTopLeftRadius: 10,
		borderTopRightRadius: 10,
		[theme.breakpoints.down('md')]: {
			bottom: '10%',
			left: '-5px',
		}
	},
	theButtons: {
		padding: '10px 15px',
		display: 'flex',
		gap: 20,
		alignItems: 'center',
		background: '#eee',
		borderTopRightRadius: 5,
		borderBottomRightRadius: 5,
		"& span": {
			color: '#000',
			fontWeight: 'bold',
			display: 'flex',
			alignItems: 'center',
			gap: 3,
			cursor: 'pointer',
			zIndex: 9
		}
	},
	arrowButton: {
		color: '#000',
		position: 'relative',
		borderTopRightRadius: 100,
		borderBottomRightRadius: 100,
		padding: 10,
		display: 'flex',
		background: '#b8e994',
		boxShadow: '0px 0px 22px 0px rgba(0,0,0,0.36)',
		alignItems: 'center',
		cursor: 'pointer',
		transition: 'transform 0.3s ease',
		'&:hover': {
			transform: 'scale(1.2)',
		},
	},
	linkHelp: {
		cursor: 'pointer',
		display: 'flex',
		textAlign: 'center',
		alignItems: 'center',
		gap: 5,
		fontSize: 16,
		fontWeight: 'bold',
	}
}));

const Chat = () => {
	const classes = useStyles();
	const { ticketId } = useParams();
	const [fullscreen, setFullscreen] = useState(false);
	const [mode, setMode] = useState(false);
	const history = useHistory();
	const [buttonArrow, setButtonArrow] = useState(false);
	const { user } = useContext(AuthContext);
	const [searchParam, setSearchParam] = useState("");

	useEffect(() => {
        const pathname = history.location.pathname;

        const match = pathname.match(/^\/search\/tickets\/([^/]+)$/);

        if (match) {
            const search = match[1];
            setSearchParam(search);
        }
    }, [history.location.pathname]);

	const handleSetMode = (item) => {
		if (item === 'drag') {
			setMode(true);
			window.localStorage.setItem('modeTickets', 'grid');
		} else {
			setMode(false);
			window.localStorage.removeItem('modeTickets');
		}
	};

	useEffect(() => {
		async function getState() {
			const modeItem = window.localStorage.getItem('modeTickets');
			if (modeItem) {
				setMode(true);
			}
		}
		getState();
	}, [])
	
	return (
		<Can
			role={user.roleId}
			perform="tickets:show:page"
			yes={() => (
				<div className={classes.root}>
					<div className={classes.chatContainer}>
						<Title className={classes.title}>{/*<PiChatTeardropText size={24} /> {i18n.t("Atendimentos")}*/}</Title>


						<div className={`${classes.chatPapper} modeChatPaper`}>
							{mode ? (<>
								<Grid container spacing={5}>
									<Grid item xs={!fullscreen ? 12 : 0} md={!fullscreen ? 12 : 0} style={fullscreen ? { width: 0 } : { width: 'auto' }}
										className={ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper}>
											<TicketsGridManager searchParam={searchParam}/>
									</Grid>
								</Grid>
							</>) : (
								<>
									<Grid container spacing={5}>
										<Grid item xs={!fullscreen ? 12 : 0} md={!fullscreen ? 4 : 0} style={fullscreen ? { width: 0 } : { width: 'auto' }}
											className={ticketId ? classes.contactsWrapperSmall : classes.contactsWrapper}>
												<TicketsManager />
										</Grid>

										<Grid item xs={12} md={!fullscreen ? 8 : 12} className={classes.messagessWrapper} style={fullscreen ? { paddingTop: 0 } : { paddingTop: 40 }}>
											{ticketId ? (
												<>
													<Ticket setFullscreen={setFullscreen} fullscreen={fullscreen} />
												</>
											) : (
												<Hidden only={['sm', 'xs']}>
													<Paper className={classes.welcomeMsg}>
														<BiMessageSquareX size={180} color="#ddd" />
														<span>{i18n.t("chat.noTicketMessage")}</span>
													</Paper>
												</Hidden>
											)}
										</Grid>
									</Grid>
								</>
							)}
						</div>

						{/**Novo */}
						<div className={classes.flexButtons}>
							{buttonArrow &&
								(<div className={classes.theButtons}>
									<span>Modo:</span>
									<span onClick={() => handleSetMode('normal')}><BsFillGridFill /> Clássico</span>
									<span onClick={() => handleSetMode('drag')}><BsFillGrid3X3GapFill /> Grid</span>
									<div onClick={() => history.push('/docs')}
										className={classes.linkHelp}><BiHelpCircle /> Ajuda</div>
								</div>)}


							<div className={classes.arrowButton}>
								{!buttonArrow ?
									<FaArrowRight onClick={() => setButtonArrow(true)} />
									: <FaArrowLeft onClick={() => setButtonArrow(false)} />
								}
							</div>
						</div>
						{/**Novo */}
					</div>
				</div>
			)}
			no={() => <>
				<ForbiddenPage />
			</>}
		/>
	);
};

export default Chat;
