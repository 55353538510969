import React, { useState, useEffect } from "react";
import { Grid, Paper, Button, Tooltip, IconButton } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import api from "../../services/api";
import toastError from "../../errors/toastError";
import { useHistory } from "react-router-dom";
import DashboardInfoBox from "../Dashboard/BoxDash";
import {
  Group,
  SouthEast, Clear, PersonRemove, PersonAdd, AttachMoney,
} from "@mui/icons-material";
import { FaInfoCircle } from "react-icons/fa";
import { i18n } from "../../translate/i18n";
import TicketTable from "./Table";
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from "@mui/material";


const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    padding: 10,
    overflow: 'hidden'
  },
  fixedHeightPaper: {
    //padding: theme.spacing(2),
    padding: 6,
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
    height: 240,
    borderRadius: 0
  },
  highchartPaper: {
    height: 'auto',
    paddingLeft: '10px',
    marginBottom: 10,
    maxWidth: '100%',
    boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
  },
  blockListPaper: {
    height: 'auto',
    paddingLeft: '10px',
    paddingTop: '15px',
    paddingBottom: '15px',
    marginBottom: 10,
    maxWidth: '100%',
    rounded: 10,
    boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
  },
  filterContainer: {
    backgroundColor: '#FFF',
    marginBottom: 10
  },
  paper: {
    padding: theme.spacing(2),
    boxShadow: "0px 1px 10px 0px rgba(0,0,0,0.07)",
  },
  wrapperSelect: {
    padding: 0
  },
  totalPaper: {
    fontSize: 20
  }
}));

const MultipleChartComponent = ({ period, userIds, contactIds, statuses, queueIds, tagIds, endpoint, title, yAxisTitle, colorChart, setLoading, theme, subtitle }) => {
  const [chartData, setChartData] = useState([]);
  const classes = useStyles();
  const [transformedChartData, setTransformedChartData] = useState([]);
  const [internalLoading, setInternalLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const [titleAxis, setYtitle] = useState('');
  const [loading, setLoadingState] = useState(false);
  const [totalResolved, setTotalResolved] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [totalEarning, setTotalEarning] = useState(0);
  const [totalLoss, setTotalLoss] = useState(0);
  const [totalLossAmount, setTotalLossAmount] = useState(0);
  const [totalCancel, setTotalCancel] = useState(0);
  const [totalWithdrawal, setTotalWithdrawal] = useState(0);
  const [typeChart, setTypeChart] = useState([]);
  const themeApp = useTheme();

  const downloadExport = async () => {
    setExporting(true);
    try {
      const format = 'xlsx';
      const dateStart = moment(period[0]).format('YYYY-MM-DD');
      const dateEnd = moment(period[1]).format('YYYY-MM-DD');
      const userIdsString = JSON.stringify(userIds);
      const tagIdString = tagIds ? JSON.stringify(tagIds) : '';
      const statusesString = statuses ? JSON.stringify(statuses) : '';
      const keysString = typeChart ? JSON.stringify(typeChart) : '';
      const { data } = await api.get(`/report/export/reviews?userIdsString=${userIdsString}&tagIdsString=${tagIdString}&statuses=${statusesString}&dateStart=${dateStart}&dateEnd=${dateEnd}&format=${format}&keys=${keysString}&`, { responseType: 'arraybuffer', headers: { 'Content-Type': 'application/json', 'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, text/csv' } });
      const blob = new Blob([data]);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = `historico_atendimentos_concluidos.${format}`;
      link.click();
      setExporting(false);
    } catch (err) {
      toastError(err);
      setExporting(false);
    }
  };



  useEffect(() => {
    let isMounted = true;
    setYtitle(yAxisTitle);
    const fetchData = async () => {
      try {
        setInternalLoading(true);
        const response = await api.get(endpoint, {
          params: {
            dateStart: moment(period[0]).format("YYYY-MM-DD"),
            dateEnd: moment(period[1]).format("YYYY-MM-DD"),
            contactIds: JSON.stringify(contactIds),
            userIds: JSON.stringify(userIds),
            queueIds: JSON.stringify(queueIds),
            statuses: JSON.stringify(statuses),
            tagIds: JSON.stringify(tagIds),
            keys: JSON.stringify(typeChart)
          },
        });

        if (response.data && isMounted) {
          //console.log('received data multiple', response);
          setChartData(response.data.data);
          const aggregation = {};

          const reasonMap = {
            'withdrawal': 'Desistencias',
            'cancel': 'Cancelamentos',
            'loss': 'Perda',
            'earning': 'Ganho'
          };

          response.data.data.forEach(entry => {
            const { name, reason } = entry;
            //console.log(reason, aggregation, name)
            if (!aggregation[name]) {
              aggregation[name] = {
                name: name,
                Desistencias: 0,
                Ganho: 0,
                Cancelamentos: 0,
                Perda: 0,
              };
            }
            if (reasonMap[reason]) {
              aggregation[name][reasonMap[reason]]++;
            }
          });

          const transformedData = Object.values(aggregation);
          //console.log('test', transformedData)

          setTransformedChartData(transformedData)

          countTickets(response.data.data);

          setInternalLoading(false);
          setLoading(false);

        }
      } catch (err) {
        if (isMounted) {
          setInternalLoading(false);
          setLoading(false);
          toastError(err);
        }
      }
    };

    fetchData();

    return () => {
      isMounted = false;
    };
  }, [period, userIds, contactIds, queueIds, tagIds, statuses, endpoint, colorChart, typeChart]);





  const countTickets = (data) => {
    const aggregation = {
      totalAmount: 0,
      totalResolved: 0,
      totalEarning: 0,
      totalLoss: 0,
      totalLossAmount: 0,
      totalCancel: 0,
      totalWithdrawal: 0,
    };

    //console.log('data in here:', data);

    // Process data and calculate totals
    data.forEach(entry => {
      const { name, reason } = entry;
      switch (reason) {
        case 'withdrawal':
          aggregation.totalWithdrawal++;
          break;
        case 'earning':
          aggregation.totalEarning++;
          if (typeof entry.amount === 'number' && !isNaN(entry.amount)) {
            aggregation.totalAmount += entry.amount;
          }
          break;
        case 'cancel':
          aggregation.totalCancel++;
          break;
        case 'loss':
          aggregation.totalLoss++;
          if (typeof entry.amount === 'number' && !isNaN(entry.amount)) {
            aggregation.totalLossAmount += entry.amount;
          }
          break;
        default:
          break;
      }
    });

    // Set state with totals
    // console.log(aggregation.totalAmount, 'amount')
    setTotalResolved(aggregation.totalEarning + aggregation.totalLoss + aggregation.totalWithdrawal + aggregation.totalCancel);
    setTotalEarning(aggregation.totalEarning);
    setTotalLoss(aggregation.totalLoss);
    setTotalCancel(aggregation.totalCancel);
    setTotalAmount(aggregation.totalAmount);
    setTotalLossAmount(aggregation.totalLossAmount);
    setTotalWithdrawal(aggregation.totalWithdrawal);
  }

  const handleSelectTypeChart = (item) => {
    setTypeChart((prevTypeChart) => {
      const itemExists = prevTypeChart.some(existingItem => existingItem === item);

      if (itemExists) {
        return prevTypeChart.filter(existingItem => existingItem !== item);
      } else {
        return [...prevTypeChart, item];
      }
    });

    // console.log('type', typeChart)
  };

  return (
    <>
      <Grid container spacing={3} style={{ marginTop: 0, marginBottom: 20 }} className="block-report-actions" sx={{
        display: 'grid',
        gridAutoFlow: 'row',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gridTemplateRows: 'repeat(2, 50px)',
        gap: 1,
      }}>
        <Grid item xs={6} md="auto" lg="auto" style={{ paddingTop: 0 }}
          className={`block-report-action-item ${typeChart.includes('totalResolved') ? 'activedBox' : ''}`}>
          <DashboardInfoBox className={classes.paper}
            loading={loading}
            title={i18n.t("Avaliados")}
            value={totalResolved}
            color="#009FDD"
            icon={<Group fontSize="inherit" />} />
        </Grid>
        <Grid item xs={6} md="auto" lg="auto" style={{ paddingTop: 0 }}
          className={`block-report-action-item ${typeChart.includes('totalAmount') ? 'activedBox' : ''}`}>
          <DashboardInfoBox className={classes.paper}
            loading={loading} color="#2ECC71"
            title={i18n.t("Total Ganho R$")}
            value={totalAmount}
            icon={<AttachMoney fontSize="inherit" />}
            price />
        </Grid>
        <Grid item xs={6} md="auto" lg="auto" style={{ paddingTop: 0 }}
          className={`block-report-action-item ${typeChart.includes('totalLossAmount') ? 'activedBox' : ''}`}>
          <DashboardInfoBox className={classes.paper}
            loading={loading}
            title={'Total Perda R$'}
            color="#F61C05"
            value={totalLossAmount}
            onClick={() => handleSelectTypeChart('totalLossAmount')}
            icon={<AttachMoney fontSize="inherit" />}
            price />
        </Grid>
        <Grid item xs={6} md="auto" lg="auto" style={{ paddingTop: 0 }}
          className={`block-report-action-item ${typeChart.includes('earning') ? 'activedBox' : ''}`}
          onClick={() => handleSelectTypeChart('earning')}
        >
          <DashboardInfoBox className={classes.paper}
            loading={loading} color="#1DCC71"
            title={"Ganho"} value={totalEarning}
            icon={<PersonAdd fontSize="inherit" />} />

        </Grid>
        <Grid item xs={6} md="auto" lg="auto" style={{ paddingTop: 0 }}
          className={`block-report-action-item ${typeChart.includes('loss') ? 'activedBox' : ''}`}
          onClick={() => handleSelectTypeChart('loss')}
        >
          <DashboardInfoBox className={classes.paper}
            loading={loading}
            title={'Perda'}
            color="#F61C05"
            value={totalLoss}
            icon={<PersonRemove fontSize="inherit" />} />
        </Grid>

        <Grid item xs={6} md="auto" lg="auto" style={{ paddingTop: 0 }}
          className={`block-report-action-item ${typeChart.includes('cancel') ? 'activedBox' : ''}`}
          onClick={() => handleSelectTypeChart('cancel')}>
          <DashboardInfoBox
            className={classes.paper}
            loading={loading}
            color="#9B59B6"
            title={'Cancelamentos'}
            value={totalCancel}
            onClick={() => handleSelectTypeChart('totalCancel')}
            icon={<Clear fontSize="inherit" />} />
        </Grid>

        <Grid item xs={6} md="auto" lg="auto" style={{ paddingTop: 0 }}
          className={`block-report-action-item ${typeChart.includes('withdrawal') ? 'activedBox' : ''}`}
          onClick={() => handleSelectTypeChart('withdrawal')}>
          <DashboardInfoBox
            loading={loading}
            color="#F1C40F" title="Desistências"
            value={totalWithdrawal}

            icon={<SouthEast fontSize="inherit" />} />
        </Grid>
      </Grid>

      <Paper className={classes.highchartPaper} style={{ marginTop: 50, paddingTop: 0 }} >
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} style={{ paddingTop: 10 }}>
            <h2 style={{ fontSize: 20, margin: '0px 15px' }}>
              <div className="block-title-chart" style={{ display: 'flex', width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <span className="title-chart" style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                  {title}
                  <Tooltip title={`Este gráfico exibe a classificação de atendimentos finalizados pelo usuário.`}>
                    <IconButton>
                      <FaInfoCircle size={15} />
                    </IconButton>
                  </Tooltip>
                </span>


                <small style={{ fontSize: 13, textAlign: 'center', lineHeight: '1.4' }}>
                  * Não se aplica filtragem por status
                  <br />Visualização somente de atendimentos concluídos avaliados
                </small>

                <Button variant="outlined" size="small" onClick={() => downloadExport('open')} startIcon={<img src="/icons/xlsx-icon.png" height={30} />}>
                  Exportar Planilha
                </Button>
              </div>
            </h2>

            {chartData && chartData.length > 0 ? (
              <div style={{ height: 500, width: '100%', display: 'flex' }}>
                <ResponsiveBar
                  data={transformedChartData}
                  keys={[
                    'Desistencias',
                    'Cancelamentos',
                    'Perda',
                    'Ganho'
                  ]}
                  indexBy="name"
                  height={500}
                  margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
                  padding={0.3}
                  valueScale={{ type: 'linear' }}
                  indexScale={{ type: 'band', round: true }}
                  colors={{ scheme: theme }}
                  colorBy="id"
                  borderColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  theme={{
                    text: {
                      fill: themeApp.mode === 'dark' ? '#ffffff' : '#000000'
                    }
                  }}
                  enableTotals
                  axisTop={null}
                  axisRight={null}
                  axisBottom={{
                    tickSize: 5,
                    tickPadding: 0,
                    tickRotation: -15,
                    legendPosition: 'start',
                    legendOffset: 2,
                    truncateTickAt: 100
                  }}
                  axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: titleAxis,
                    format: value => value !== 0 ? `${value.toFixed(0)}` : null,
                    legendPosition: 'middle',
                    legendOffset: -40,
                  }}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]],
                  }}
                  legends={[
                    {
                      dataFrom: 'keys',
                      anchor: 'top-right',
                      direction: 'column',
                      justify: false,
                      translateX: 100,
                      translateY: 0,
                      itemsSpacing: 4,
                      itemWidth: 100,
                      itemHeight: 10,
                      itemDirection: 'left-to-right',
                      itemOpacity: 0.85,
                      symbolSize: 20,
                      effects: [
                        {
                          on: 'hover',
                          style: {
                            itemOpacity: 1,
                          },
                        },
                      ],
                    },
                  ]}
                  role="application"
                  ariaLabel="Nivo bar chart demo"
                  barAriaLabel={e => e.formattedValue + e.indexValue + "atendimentos"}
                  tooltip={({
                    id,
                    value,
                    indexValue,
                    color,
                    amount
                  }) => <div style={{
                    padding: 12,
                    color,
                    background: '#222222',
                    textAlign: 'center'
                  }}>
                      <h5>{id}</h5>
                      <strong>
                        {indexValue} - {value} atendimento(s) {amount && (<small>{amount}</small>)}
                      </strong>
                    </div>


                  }
                />


              </div>
            ) : chartData && chartData.length === 0 ? (
              <div
                className="boxChart"
                style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
              >
                {/*<img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />*/}
                Nada encontrado
              </div>) : (
              <div
                className="boxChart"
                style={{ height: 400, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '10px', justifyContent: 'center' }}
              >
                <img src="/loading/loadinginfinite.gif" height={30} alt="Loading..." />
                Carregando dados...
              </div>
            )}

            <TicketTable chartData={chartData} />
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default MultipleChartComponent;
