import React from "react";
import { makeStyles } from "@mui/styles";
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm'
import clsx from "clsx";
import { Typography, Tooltip } from "@mui/material";

const useStyles = makeStyles(theme => ({
    oneline: {
        display: "inline-block",
        overflow: "hidden",
        whiteSpace: "nowrap",
        maxHeight: 15
    },
    paragraph: {
        margin: 0,
        padding: 0,
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    },
    listContainer: {
        //marginTop:-40
    },
    olList:{
        marginBottom: 0,
        paddingLeft: 10
    },
    ulList:{
        marginBottom: 0
    },
    listItem:{
       //padding: 0
    }
}));

const MarkdownWrapper = ({ children, ...props }) => {
    const boldRegex = /\*(.*?)\*/g;
    const tildaRegex = /~(.*?)~/g;
    const listItemRegex = /^(\d+)\. /gm;

    const classes = useStyles();

    if (children && boldRegex.test(children)) {
        children = children.replace(boldRegex, "**$1**");
    }
    if (children && tildaRegex.test(children)) {
        children = children.replace(tildaRegex, "~~$1~~");
    }
    if (children && listItemRegex.test(children)) {
        children = children.replace(listItemRegex, '$1\\. ');
    }

    function LinkRenderer(rendererProps) {
        const isEmail = (text) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(text);    
        return (
            <a href={isEmail(rendererProps.children) ? rendererProps.href : rendererProps.children} target="_blank" rel="noreferrer">
                {rendererProps.children}
            </a>
        );
    }

    function paragraphRenderer(rendererProps) {
        return (
            <div className={classes.paragraph}>{rendererProps.children}</div>
        )
    }

    function listItemRenderer(rendererProps) {
        return (
            <li className={`${classes.listItem} listLi`}>{rendererProps.children}</li>
        );
    }

    function listRenderer(rendererProps) {
        const { ordered, children, properties } = rendererProps;
        return (
            <div className={classes.listContainer}>
                {ordered ? <ol className={classes.olList}>{children}</ol> : <ul className={classes.ulList}>{children}</ul>}
            </div>
        );
    }

    if (!children) return null;

    return <Markdown
        remarkPlugins={[remarkGfm]}
        className={clsx({ [classes.oneline]: props.oneline })}
        components={{
            a: LinkRenderer,
            p: paragraphRenderer,
            ul: listRenderer,
            li: listItemRenderer
        }}
        children={children} />;
};

export default MarkdownWrapper;
